@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-[#1f1f1f];
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* input[type="number"] {
    -moz-appearance: textfield;
  } */
}

.maxZIndex {
  z-index: 250000 !important;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ant-btn-primary {
  background-color: #1677ff !important;
}
.ant-btn-primary:disabled {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
body {
  overflow-y: hidden;
}

.job-equipment-photo .ant-image-mask {
  height: 224px !important;
  width: 224px !important;
}

.react-pdf__Page {
  z-index: 1;
  background-color: transparent !important;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: max-content;
  height: max-content;
  -webkit-box-shadow: 3px 3px 14px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 3px 3px 14px 0px rgba(0, 0, 0, 0.14);
}
.react-pdf__Page__canvas {
  margin: 0 auto;
}

.react-pdf__Page__annotations {
  display: none;
}

.phoneInput::placeholder {
  color: #9ca3af !important;
}

.phoneInput:hover {
  border-color: #d1d5db !important;
}

.hyper-elastic .sui-search-box__submit {
  font-family: inherit;
  cursor: pointer;
  background-color: transparent;
  background: none;
  background-image: none;
  display: inline-flex;
  align-items: center;
  column-gap: 0.375rem;
  border-radius: 0.375rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(17, 24, 39);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  margin: 0;
}

.hyper-elastic .sui-search-box__submit:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  background: none;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hyper-elastic form {
  width: 60%;
}
@media (max-width: 768px) {
  .hyper-elastic form {
    width: 100%;
  }
}

.hyper-elastic .sui-search-box {
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
}

.hyper-elastic .sui-search-box__text-input {
  appearance: none;
  background-color: #fff;
  border-color: transparent;
  padding-right: 0.75rem;
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  border-width: 1px;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  color: rgb(17, 24, 39);
  font-size: 0.875rem;
  line-height: 1.5rem;
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hyper-elastic .sui-search-box__text-input:focus {
  outline: none;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  border-color: #2563eb;
  box-shadow: none;
}

.hyper-elastic .sui-select__control {
  border-color: rgb(209 213 219);
  border-radius: 0.375rem;
}

.hyper-elastic .sui-select__single-value {
  color: rgb(17, 24, 39);
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
}

.hyper-elastic .sui-select__dropdown-indicator svg {
  scale: 0.75;
}

.hyper-elastic .sui-search-box__autocomplete-container {
  padding: 6px 12px;
}

.hyper-elastic .sui-search-box__autocomplete-container div {
  padding: 6px 12px;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: rgb(17, 24, 39);
}

.hyper-elastic .sui-search-box__autocomplete-container div[aria-selected="true"] {
  background: #eaeaea;
  color: rgb(17, 24, 39);
}

.ant-select-selection-search-input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: none;
}
.ant-input:focus-visible {
  outline: none !important;
}

.ant-select-selector {
  height: 38px !important;
  overflow: hidden;
}

.ant-select-multiple .ant-select-selector {
  height: 100% !important;
}

.ant-select-selection-search-input {
  height: 33px !important;
}

.ant-select-selection-placeholder {
  line-height: 36px !important;
}

.ant-select-selection-item {
  line-height: 36px !important;
}

.noOutline {
  outline: none !important;
  box-shadow: none !important;
}
.ant-select-selection-item-content {
  line-height: 1.7 !important;
}
.ant-select-clear {
  margin-top: -9px !important;
}

.hyper-elastic-full .sui-search-box__submit {
  font-family: inherit;
  cursor: pointer;
  background-color: transparent;
  background: none;
  background-image: none;
  display: inline-flex;
  align-items: center;
  column-gap: 0.375rem;
  border-radius: 0.375rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(17, 24, 39);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  margin: 0;
}

.hyper-elastic-full .sui-search-box__submit:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  background: none;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hyper-elastic-full form {
  width: 100%;
}

.hyper-elastic-full .sui-search-box {
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
}

.hyper-elastic-full .sui-search-box__text-input {
  appearance: none;
  background-color: #fff;
  border-color: transparent;
  padding-right: 0.75rem;
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  border-width: 1px;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  color: rgb(17, 24, 39);
  font-size: 0.875rem;
  line-height: 1.5rem;
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hyper-elastic-full .sui-search-box__text-input:focus {
  outline: none;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  border-color: #2563eb;
  box-shadow: none;
}

.hyper-elastic-full .sui-select__control {
  border-color: rgb(209 213 219);
  border-radius: 0.375rem;
}

.hyper-elastic-full .sui-select__single-value {
  color: rgb(17, 24, 39);
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
}

.hyper-elastic-full .sui-select__dropdown-indicator svg {
  scale: 0.75;
}

.hyper-elastic-full .sui-search-box__autocomplete-container {
  padding: 6px 12px;
}

.hyper-elastic-full .sui-search-box__autocomplete-container div {
  padding: 6px 12px;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: rgb(17, 24, 39);
}

.hyper-elastic-full .sui-search-box__autocomplete-container div[aria-selected="true"] {
  background: #eaeaea;
  color: rgb(17, 24, 39);
}
.ant-modal-confirm-content {
  max-width: none !important;
}

.ant-switch {
  background-color: rgba(172, 172, 172, 0.25) !important;
}

.ant-switch-checked {
  background: #1677ff !important;
}
.ant-table-row {
  background: #fafafa !important;
  background-color: #fafafa !important;
}

.ant-input-number-input-wrap input {
  box-shadow: none !important;
}

.ant-select-arrow {
  top: 60% !important;
}

.ant-select-item-option-content {
  white-space: wrap !important;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 6px 10px;
}

.ant-select-clear {
  top: 67% !important;
}

.ant-select-multiple .ant-select-clear {
  top: 56% !important;
}
.ant-select-multiple .ant-select-arrow {
  top: 50% !important;
}

.ant-slider .ant-slider-track {
  background-color: #18181b !important;
}
.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #18181b !important;
}
